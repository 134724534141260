<template>
  <b-card>
    <b-row>
      <b-col class="border border-primary rounded pt-1">
        <b-row>
          <b-col cols="2">
            <div class="imageContainer m-0 p-0">
              <div class="clearfix border" v-if="product.images[0]">
                <b-img
                    class="border border-primary"
                    :src="`https://api.kitapfirsatlari.com/${product.images[0].url}`"
                    width="150"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="10" class="pl-3">
            <b-row>
              <b-col>
                <b>{{ product.id }}</b><br>
                <b>{{ product.name }}</b><br>
                <div v-if="product.brand">
                  <b-badge>{{ product.brand.name }}</b-badge>
                </div>

                <b>Barkod: {{ product.barcode }}</b>
                <div v-if="product.barcodes.length>0">
                  Listesi
                  <ul>
                    <li
                        v-if="[1,2].includes(barcode.status_id)"
                        v-for="barcode in product.barcodes"
                        :key="barcode.id"
                    >
                      {{ barcode.barcode }}
                    </li>
                  </ul>
                </div>
                <product-edit-panel :product="product"/>
                <TaxRateSelector :tax-rate="product.tax_rate" :productId="product.id" :indexId="product.id"/>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col>
                <publisher
                    v-for="publisher in product.publishers"
                    :publisher="publisher"
                    :key="publisher.id"
                />
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col>
                <category
                    v-for="category in product.categories"
                    :category="category"
                    :key="category.id"
                />
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <OrderProductSupplierProduct :product="product" :productAccounting="{}"/>
            </b-row>
            <b-row class="mt-1">
              <b-button-group>
                <b-button
                    size="sm"
                    v-for="stock in product.stocks2"
                    :key="stock.id"
                    :variant="stock.stock_return===50?'outline-secondary':'outline-warning'"
                >
                  {{ supplier_name(stock.stock_return) }} - {{ stock.price }} ₺ - {{ stock.quantity }}
                </b-button>
              </b-button-group>
            </b-row>
            <b-row class="mt-1">
              <b-button-group>
                <b-button
                    size="sm"
                    variant="outline-warning"
                    v-for="mpProduct in product.hb_products"
                    :key="mpProduct.id"
                    :href="`https://www.hepsiburada.com/w-p-${mpProduct.merchant_sku}`"
                    target="_blank"
                >
                  {{ mpProduct.merchant_sku }}
                </b-button>

                <b-button
                    size="sm"
                    variant="outline-success"
                    v-for="mpProduct in product.ty_products"
                    :key="mpProduct.id"
                    :href="mpProduct.url"
                    target="_blank"
                >
                  {{ mpProduct.merchant_sku }}
                </b-button>

                <b-button
                    size="sm"
                    variant="outline-primary"
                    v-for="mpProduct in product.az_products"
                    :key="mpProduct.id"
                    :href="`https://www.amazon.com.tr/gp/product/${mpProduct.merchant_sku}`"
                    target="_blank"
                >
                  {{ mpProduct.merchant_sku }}
                </b-button>
              </b-button-group>

            </b-row>
          </b-col>
        </b-row>
      </b-col>

      <b-col class="border border-warning rounded pt-1">
        <b-row v-if="page==='product'">
          <b-col>product</b-col>
        </b-row>
        <b-row v-if="page==='stock'">
          <b-col>stock</b-col>
        </b-row>
        <b-row v-if="page==='supplier'">
          <b-col>supplier</b-col>
        </b-row>
        <b-row v-if="page==='storeProduct'">
          <b-col>storeProduct</b-col>
        </b-row>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import ProductEditPanel from "@/views/products/ProductEditPanel.vue";
import TaxRateSelector from "@/components/TaxRateSelector.vue";
import OrderProductSupplierProduct from "@/views/order/list/OrderProductSupplierProduct.vue";
import Publisher from "@/views/products/Publisher.vue"
import Category from "@/views/products/Category.vue";
import supplierMixins from '@/mixins/supplierMixins'

export default {
  name: "Product",
  mixins: [supplierMixins],
  components: {
    Category, OrderProductSupplierProduct, TaxRateSelector, ProductEditPanel, Publisher
  },
  props: {
    page: {type: String, default: 'product'},
    product: {
      required: true,
      type: Object,
    }
  },

}
</script>

<style scoped>

</style>
