<template>
  <b-badge>
    {{ publisher.publisher_name }}
  </b-badge>
</template>

<script>
export default {
  name: "Publisher",
  props: {
    publisher: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
