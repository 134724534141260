<template>
  <div>
    <b-badge
        v-if="product.id"
        variant="success"
        size="sm"
        v-b-popover.hover.top="'Ürün Bİlgileri Düzenle'"
        @click="editProduct"
    >
      <feather-icon icon="EditIcon"/>
    </b-badge>
    <b-badge
        v-if="product.id"
        variant="warning"
        size="sm"
        v-b-popover.hover.top="'Amz Sipariş Oluştur'"
        @click="addNewOrder(product.id)">
      <feather-icon icon="ShoppingCartIcon"/>
    </b-badge>
    <b-badge
        v-if="product.id"
        variant="info"
        size="sm"
        v-b-popover.hover.top="'Satış İstatistikleri'"
        @click="getOrderProductStatistics($event,product.id)">
      <feather-icon icon="BarChart2Icon"/>
    </b-badge>
    <b-badge
        variant="warning"
        v-b-popover.hover.top="'Yeni Barkod Ekle'"
        @click="addProductBarcode(product.id)"
    >
      <b-icon-bookmark-plus></b-icon-bookmark-plus>
    </b-badge>
    <b-badge
        v-if="['admin','manager'].includes(user.role)"
        variant="success"
        v-b-popover.hover.top="'Barkod Listesi Düzenle'"
        @click="getProductBarcodeList($event,product.id)"
    >
      <b-icon-bookmarks></b-icon-bookmarks>
    </b-badge>
    <b-badge
        v-if="user.roles.includes('manager')"
        variant="warning"
        v-b-popover.hover.top="'KDV Düzelt'"
        @click="vatSelector"
    >
      <feather-icon icon="DollarSignIcon"/>
    </b-badge>
    <b-badge
        variant="info"
        v-b-popover.hover.top="'Stokları yeniden hesapla'"
        @click="recalculateInventory([product.id])">
      <feather-icon icon="PackageIcon"/>
    </b-badge>
    <b-badge
        variant="primary"
        v-b-popover.hover.top="'Mağaza detayları'"
        @click="storeProductDetail($event,product.id)">
      <feather-icon icon="PackageIcon"/>
    </b-badge>
  </div>
</template>

<script>
import addNewOrderMixins from "@/mixins/addNewOrderMixins";
import recalculateInventoryMixins from "@/mixins/recalculateInventoryMixins";

export default {
  name: "ProductEditPanel",
  mixins: [addNewOrderMixins, recalculateInventoryMixins],
  data: () => ({
    user: JSON.parse(localStorage.getItem('userData')),
  }),
  props: {
    product: {
      required: true,
      type: Object,
    }
  },
  methods: {
    editProduct($event) {
      this.$store.commit('product/setEditProduct', this.product);
      this.$root.$emit('bv::show::modal', 'edit-product-modal', $event.target)
    },
    getOrderProductStatistics(event, produdctId) {
      this.$store.state.order.orderProductStatisticsProductId = produdctId;
      this.$root.$emit('bv::show::modal', 'order-product-statistics-modal', event.target)
    },
    addProductBarcode(productId) {
      let newBarcode = prompt("Lütfen Yeni Barkodu Giriniz", "");
      if (newBarcode != null) {
        this.$store.dispatch('product/storeProductBarcode', {product_id: productId, barcode: newBarcode})
            .then(res => {
              this.$swal({
                title: res.message,
                icon: res.icon,
              })
            })
      }
    },
    getProductBarcodeList(event, productId) {
      this.$store.commit('product/setEditProduct', productId);
      this.$root.$emit('bv::show::modal', 'edit-product-barcodes-modal', event.target)
    },
    storeProductDetail(event, productId) {
      this.$root.$emit('productStoreDetailProductId', productId);
      this.$root.$emit('bv::show::modal', 'product-store-detail', event.target)
    },
    async vatSelector() {
      /* inputOptions can be an object or Promise */
      const {value: vatRate} = await this.$swal.fire({
        title: 'KDV Oranı Seçiniz',
        input: 'radio',
        inputOptions: {'0': '%0', '1': '%1', '8': '%8', '18': '%18'},
        inputValidator: (value) => {
          if (!value) {
            return 'Bir KDV oranı seçmelisiniz!'
          }
        }
      })

      if (vatRate) {
        await this.$store.dispatch('product/updateProduct', {id: this.product.id, tax_rate: vatRate})
            .then(res => {
              //this.$swal({html: `${res.message} (${vatRate})`})
            })
      }
    },
  }
}
</script>

<style scoped>

</style>
