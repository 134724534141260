<template>
  <b-badge>{{ category.name }}</b-badge>
</template>

<script>
export default {
  name: "Category",
  props:{
    category:{
      type:Object,
      required:true
    }
  }
}
</script>

<style scoped>

</style>
