<template>
  <b-card>
    <search/>
  </b-card>
</template>

<script>
import Search from "@/views/product/Search.vue";

export default {
  name: "Filters",
  components: {Search},
}
</script>

<style scoped>

</style>
