<template>
  <div>
    <filters/>
    <b-card>
      <b-row>
        <b-col>
          <b-button-group>
            <b-button @click="setPage('product')">Ürün</b-button>
            <b-button @click="setPage('stock')">Stoklar</b-button>
            <b-button @click="setPage('supplier')">Tedarikçiler</b-button>
            <b-button @click="setPage('storeProduct')">Mağaza Ürünleri</b-button>
            <b-button @click="setPage('product')">A</b-button>
            <b-button @click="setPage('product')">B</b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-card>
    <product
        v-for="product in _products"
        :page="page"
        :key="product.id"
        :product="product"
    />

    <product-edit/>
    <order-product-statistics/>
    <edit-product-barcodes/>
    <store-product-detail/>
  </div>
</template>

<script>
import Filters from "@/views/products/Filters.vue";
import Product from "@/views/products/Product.vue";
import ProductEdit from "@/views/product/Edit";
import OrderProductStatistics from "@/views/order/list/OrderProductStatistics";
import EditProductBarcodes from "@/views/product/EditProductBarcodes";
import StoreProductDetail from "@/views/product/StoreProductDetail.vue";
import {mapGetters} from "vuex";

export default {
  name: "Home",
  components: {Filters, Product, ProductEdit, OrderProductStatistics, EditProductBarcodes, StoreProductDetail},
  data: () => ({
    page: 'product',
  }),
  computed: {
    ...mapGetters('product', ["_products"]),
  },
  methods: {
    setPage(page) {
      this.page = page;
    }
  },
  mounted() {
    this.$store.dispatch('supplier/suppliers');
  }
}
</script>

<style scoped>

</style>
